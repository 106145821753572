import {Dialog} from "./common";
import {parseImage} from "../math/writer";

export const action = "add-image";

export class DialogBox extends Dialog{
	$show(box, form){
		this.cell = this.view.getSelectedId();
		if(!this.cell)
			return false;

		form.elements.preview.setHTML("");
		form.elements.url.setValue("");

		//sync state of dialog to data with  the selected cell
		let value = this.view.getCellValue(this.cell.row, this.cell.column);
		let data = parseImage(value);
		if (data && data.url)
			form.elements.url.setValue(data.url);
	
		form.elements.url.focus();
	}
	$init(){

		var save = this.view.config.save;
		var server = (save && save.images) || null;
		
		return {
			view: "ssheet-dialog",
			head: webix.i18n.spreadsheet.labels["image-title"],
			move: true,
			position: "center",
			body:{
				view:"form",
				elements:[
					{ view:"text", name:"url",  placeholder:webix.i18n.spreadsheet.labels["image-url"], on:{
						"onChange": showPreview
					}},
					{ view:"label", label:webix.i18n.spreadsheet.labels["image-or"], align: "center"},
					{ view:"uploader", label:webix.i18n.spreadsheet.labels["image-upload"], upload:server, on:{
						"onBeforeFileAdd": startLoad,
						"onFileUpload": endLoad
					}},
					{ view:"formlate", name:"preview", borderless: true, css: "webix_ssheet_preview", template:"", height:50 }

				]
			},
			on:{
				onSaveClick: () => okClick(this),
				onHideClick: () => this.close(),
				onCancelClick: () => this.close()
			}
		};
	}
}


function startLoad(file){ 
	if (!this.config.upload){
		var reader = new FileReader();
		reader.onload = (e) => this.getFormView().elements.url.setValue(e.target.result);
		reader.readAsDataURL(file.file);
		return false;
	} else
		this.getFormView().elements.preview.setHTML("");
}

function endLoad(item, response){
	this.getFormView().elements.url.setValue(response.imageURL);
}

function okClick(dialog){
	var cell = dialog.cell;
	var image = dialog.$dialog.getBody().elements.url.getValue();
	if (!/^(https?:\/|data:image)\//i.test(image))
		image = "http://" + image;
	dialog.view.addImage(cell.row, cell.column, image);
	dialog.close();
}

function showPreview(item){
	if (item)
		this.getFormView().elements.preview.setHTML("<img class='webix_ssheet_cimage' src='"+item+"'></img>");
}