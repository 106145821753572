function getText(text){
	var locale = webix.i18n.spreadsheet;
	return locale.menus[text] || locale.labels[text] || locale.tooltips[text] || text;
}
function calcButtons(){
	var i, name, width,
		groups = {
			"undo":["undo","redo"],
			"insert":["add-sparkline","add-image","add-comment"]
		},
		result = {};

	for( name in groups){
		result[name] = 0;
		for(i=0; i < groups[name].length; i++){
			width = webix.html.getTextSize(getText(groups[name][i]), "webix_ssheet_button_measure").width + 3;
			result[name] = Math.max(width,result[name]);
		}
	}
	return result;
}
export function getAllElements(){
	var sizes = calcButtons();

	return [
		{
			padding: 3,
			margin: 0,
			rows:[
				{
					margin: 2,
					cols: [
						{
							name: "sheet", view: "ssheet-button-icon-top", label: getText("sheet"), arrow: true,
							options:[
								{id: "new-sheet"},
								{id: "copy-sheet"},
								{id: "remove-sheet"}
							]
						},
						{
							rows:[
								{$button: "excel-import"},
								{$button: "excel-export"}
							]
						},
						{
							rows:[
								{$button: "print"}
							]
						}
					]
				},
				{},
				{template: getText("file"), view: "ssheet-bar-title", height: 24, width: 85}
			]
		},
		{view: "ssheet-separator"},
		{
			padding: 3,
			rows:[
				{$button: "undo", view: "ssheet-button", label: getText("undo"), width: sizes.undo },
				{$button: "redo", view: "ssheet-button", label: getText("redo"), width: sizes.undo},
				{template: getText("undo-redo"), view: "ssheet-bar-title", height: 24}
			]
		},
		{view: "ssheet-separator"},
		{
			padding: 3,
			rows:[
				{
					margin: 2,
					cols:[
						{
							margin: 2,
							cols:[
								{$button: "font-family", width: (webix.skin.$active.inputHeight+2)*3+4},
								{$button: "font-size"}
							]
						},
						{$button: "borders"}
					]
				},
				{
					margin: 2,
					cols:[
						{
							margin: 2,
							cols:[
								{$button: "font-weight"},
								{$button: "font-style"},
								{$button: "text-decoration"}
							]
						},

						{$button: "background"},
						{$button: "color"}
					]

				},
				{template: getText("font"), view: "ssheet-bar-title", height: 24}
			]
		},
		{view: "ssheet-separator"},
		{
			padding: 3,
			rows:[
				{
					margin: 2,
					cols:[
						{$button: "text-align"},
						{$button: "span"}
					]
				},
				{
					margin: 2,
					cols:[
						{$button: "vertical-align"},

						{$button: "wrap"}
					]
				},
				{template: getText("align"), view: "ssheet-bar-title", height: 24}
			]
		},

		{view: "ssheet-separator"},
		{
			padding: 3,
			rows:[
				{$button: "format"},
				{
					margin: 2,
					cols:[
						{$button: "increase-decimals"},
						{$button: "decrease-decimals"}
					]
				},
				{},
				{template: getText("number"), view: "ssheet-bar-title", height: 24}
			]
		},
		{view: "ssheet-separator"},
		{
			padding: 3,
			rows:[
				{cols:[
					{ $button: "sort-asc"},
					{ $button: "create-filter"},
					{ $button: "conditional-format"},
					{ $button: "add-link"},
					{ $button: "clear"}
				]},
				{cols:[
					{ $button: "sort-desc"},
					{ $button: "add-range"},
					{ $button: "lock-cell"},
					{ $button: "add-dropdown"}
				]},
				{template: getText("edit"), view: "ssheet-bar-title", height: 24}
			]
		},
		{view: "ssheet-separator"},
		{
			padding: 3,
			rows:[
				{
					cols:[
						{$button: "add-image", view: "ssheet-button", label: getText("image"), width:sizes.insert},
						{$button: "add-comment", view: "ssheet-button", label: getText("comment"), width:sizes.insert},
					]
				},
				{$button: "add-sparkline", view: "ssheet-button", label: getText("graph"), width:sizes.insert},
				
				{template: getText("insert"), view: "ssheet-bar-title", height: 24}
			]
		},

		{view: "ssheet-separator"},
		{
			padding: 3,
			rows:[
				{
					cols:[
						{rows:[
							{$button:"row"},
							{$button:"column"}
						]},
						{
							rows:[
								{$button:"hide-gridlines"},
								{$button:"hide-headers"}
							]
						},
						{
							rows:[
								{$button:"freeze-rows"},
								{$button:"freeze-columns"}
							]
						}
					]
				},
				{template: getText("view"), view: "ssheet-bar-title", height: 24}
			]
		},
		{view: "ssheet-separator"},
		{}
	];
}