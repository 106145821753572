export const toolbarSizes = {
	width:38,
	margin: 7,
	paddingY: 1,
	sectorPadding: 5,
	sectorMargin: 0
};

// default styles for toolbar elements
export const defaultStyles = {
	"color"         : "#666666",
	"background"    : "#ffffff",
	"font-family"   : "'PT Sans', Tahoma",
	"font-size" : "15px",
	"text-align": "left",
	"vertical-align": "top",
	"white-space": "nowrap",
	"format": "-1"
};

export function setSkinDefaults(){
	const material = webix.skin.$name == "material" || webix.skin.$name == "mini";
	toolbarSizes.width = webix.skin.$active.inputHeight+2;

	defaultStyles["font-family"] = material ? "'Roboto', sans-serif" : "'PT Sans', Tahoma";
}