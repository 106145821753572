import * as parser 			from "./parser";

//convert math string to the js function
var functions = {};
export function _parse(value, core, passive){
	var struct = parser.parse(value, core, passive);
	if (!functions[value] || passive){
		functions[value] = _build_function(struct.code);
	}
	struct.handler = functions[value];
	return struct;
}

export function clearMathCache(){
	functions = {};
}

function error_function(){
	return webix.i18n.spreadsheet.table["math-error"];
}
function _build_function(value){
	try{
		return new Function(value);
	} catch(e){
		return error_function;
	}
}